@import '../../styles/variables';

footer {
  background-color: $dark-bagcground;
  color: $info-txt;
  padding-block: 2rem;
  padding-inline: 1rem;

  .footer-row {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }

    .header {
      font-family: $font-family-merriweather;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .footer-txt {
      font-family: $font-family-crimson-pro;
      font-size: 16px;

      &:first-of-type {
        max-width: 350px;
      }

      @media screen and (min-width: 600px) {
        &:first-of-type {
          max-width: 200px;
        }
      }
    }

    .kontakt-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      svg {
        margin-right: 5px;
      }
    }

    .social-media {
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: $site-background;
        background-color: $info-txt;
        transition: all 300ms ease-in;

        @media screen and (min-width: 600px) {
          width: 50px;
          height: 50px;
        }

        svg {
          font-size: 25px;
        }

        &.facebook:hover {
          background-color: $facebook--icon;
          color: $info-txt;
        }
        &.instagram:hover {
          background: rgb(242, 4, 212);
          background: linear-gradient(138deg, $instagram--icon--one 0%, $instagram--icon--two 100%);
          color: $info-txt;
        }
        &.twitter:hover {
          background-color: $twitter--icon;
          color: $info-txt;
        }
      }

      a + a {
        margin-left: 1rem;
      }
    }
  }

  .footer-row > * {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
  }
}
