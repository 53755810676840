@import './styles/variables';

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(126, 126, 126) #494949;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #494949;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(126, 126, 126);
  // border-radius: 20px;
  // border: 3px solid #333;
}

body {
  background-color: $dark-bagcground;
  position: relative;
  overflow-x: hidden;
}

.container {
  width: 100%;
  @media screen and (min-width: 900px) {
    max-width: 1144px;
    margin-inline: auto;
  }
}

.overlay-60 {
  position: relative;
  isolation: isolate;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
}
