@import '../../styles/variables';

nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: unset;
  transition: background-color 300ms ease;

  .message {
    background-color: $dark-bagcground;
    border-radius: 0 20px 20px 0;
    border-left: 25px solid green;
    color: $color-ffffff;
    font-family: $font-family-crimson-pro;
    font-size: 18px;
    padding-block: 2rem;
    padding-inline: 2rem;
    z-index: 20;
    position: absolute;
    left: 0;
    top: 100%;
    translate: -100%;
    transition: translate 400ms 200ms;

    @media screen and (min-width: 900px) {
      left: 50%;
      transform: translateX(-50%);
      padding-inline: 4rem;
      width: 400px;
      border: 3px solid green;
      border-radius: 20px;
      translate: 0%;
      opacity: 0;
      visibility: hidden;
      transition: all 400ms 200ms;
    }
  }

  .message.appear {
    translate: 0%;

    @media screen and (min-width: 900px) {
      opacity: 1;
      visibility: visible;
    }
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 30;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms 300ms ease-in;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $site-background;
    width: 80%;
    max-width: 500px;
    padding-inline: 3rem;
    padding-block: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 40;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms 300ms ease-in;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      button {
        width: fit-content;
      }
    }

    svg {
      color: $color-ffffff;
      font-size: 2rem;
      float: right;
      cursor: pointer;
    }

    h3 {
      font-family: $font-family-merriweather;
      font-weight: $font-weight-bold;
      font-size: 24px;
      color: $color-ffffff;
    }
  }
}

.scroll {
  background-color: $dark-bagcground;
}

.navbar {
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  padding-block: 1rem;
  font-family: $font-family-merriweather;
  font-weight: $font-weight-bold;

  @media screen and (min-width: 900px) {
    background-color: unset;
  }

  @media screen and (min-width: 1000px) {
    padding-inline: 0;
  }

  .logo {
    fill: $color-ffffff;
    height: 40px;
  }

  ul.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
    list-style: none;
    position: absolute;
    background-color: $site-background;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 2rem;
    translate: -100%;
    transition: translate 300ms 300ms ease;

    @media screen and (min-width: 900px) {
      position: initial;
      translate: 0%;
      flex-direction: row;
      height: unset;
      padding-top: 0;
      width: unset;
      margin-left: auto;
      background-color: unset;
    }

    .dropdown {
      display: flex;
      gap: 2rem;
      width: max-content;
      position: absolute;
      translate: -50%;
      left: 50%;
      top: 20%;
      visibility: hidden;
      scale: 1 0;
      transform-origin: top;
      transition: scale 300ms ease-in, visibility 300ms ease-in;
      font-size: 1rem;

      @media screen and (min-width: 900px) {
        flex-direction: column;
        background-color: $dark-bagcground;
        gap: 1rem;
        top: 45px;
        left: 80px;
        padding-block: 10px;
        padding-inline: 15px;
        white-space: nowrap;
      }

      .dropdown__link {
        color: $info-txt;
        text-decoration: none;
      }
    }

    .dropdown.show {
      scale: 1 1;
      visibility: visible;
      width: max-content;
    }

    li.menu-link {
      display: flex;
      align-items: center;
      color: $color-ffffff;
      font-size: 1.5rem;

      @media screen and (min-width: 900px) {
        font-size: 1.2rem;
      }

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      svg {
        font-size: 2rem;
        transition: rotate 300ms 300ms ease;
      }

      svg.rotate {
        rotate: 180deg;
      }
      a {
        text-decoration: none;
        color: $color-ffffff;
        position: relative;

        @media screen and (min-width: 900px) {
          &::before {
            content: '';
            position: absolute;
            bottom: -2px;
            height: 2px;
            width: 100%;
            background-color: $btn;
            scale: 0;
            transition: scale 300ms ease-in;
          }

          &:hover::before {
            scale: 1;
          }
        }
      }
    }
  }

  ul.links.show {
    translate: 0%;
  }

  button {
    margin-left: auto;

    @media screen and (min-width: 900px) {
      margin-left: 2rem;
    }
  }

  .hamburger-menu {
    color: $color-ffffff;
    font-size: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
    z-index: 20;

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
}
