@import '../../styles/variables';

.section {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-inline: 1rem;

  @media screen and (min-width: 1200px) {
    padding-inline: 0;
  }

  .section-box {
    @media screen and (min-width: 600px) {
      padding-right: 30%;
    }

    @media screen and (min-width: 900px) {
      padding-right: 50%;
    }

    @media screen and (min-width: 1200px) {
      padding-right: 70%;
    }
  }

  .heading {
    font-size: 3.5rem;
    color: $color-ffffff;
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }

  .bodytxt {
    color: $color-ffffff;
    font-family: $font-family-crimson-pro;
    letter-spacing: 1px;
    margin-bottom: 2rem;
  }
}

.gastropub {
  background-image: url(../../../public/assets/mainsite-waiter.jpg);
  background-position: top 40% left 70%;
  background-size: cover;
}

.fantastisk-mad {
  background-image: url(../../../public/assets/mainSite-food.jpg);
  background-position: center left 70%;
  background-size: cover;
}

.luksuriost-interior {
  background-image: url(../../../public/assets/mainSite-interior01.jpg);
  background-position: center left 70%;
  background-size: cover;
}
