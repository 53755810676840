@import '../../styles/variables';

.top-arrow {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba($color-000000, 0.6);
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 10;
  display: grid;
  place-content: center;

  svg {
    color: $color-ffffff;
    font-size: 3rem;
    cursor: pointer;
  }
}
