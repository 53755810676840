@import '../../styles/variables';

.btn__gold {
  background-color: $btn;
  color: $color-000000;
  border-radius: 25px;
  font-family: $font-family-merriweather;
  font-weight: $font-weight-bold;
  border: none;
  padding-inline: 20px;
  padding-block: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
}
