@import '../../styles/variables';

.kontakt-hero {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    color: $color-ffffff;
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }
}

.kontakt-section {
  background-color: $site-background;
  padding-inline: 1rem;
  padding-block: 3rem;
}

.message {
  background-color: $dark-bagcground;
  border-radius: 0 20px 20px 0;
  border-left: 25px solid green;
  color: $color-ffffff;
  font-family: $font-family-crimson-pro;
  font-size: 18px;
  padding-block: 2rem;
  padding-inline: 4rem;
  z-index: 20;
  position: absolute;
  left: 0;
  translate: -100%;
  transition: translate 400ms 200ms;

  @media screen and (min-width: 900px) {
    left: 52%;
    top: 70%;
    width: 400px;
    border: 3px solid green;
    border-radius: 20px;
    translate: 0%;
    opacity: 0;
    transition: opacity 400ms 200ms;
  }
}

.message.appear {
  translate: 0%;

  @media screen and (min-width: 900px) {
    opacity: 1;
  }
}

.kontakt-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (min-width: 900px) {
    flex-direction: row;

    & > * {
      width: 50%;
    }
  }

  .kontakt-left {
    h3 {
      font-family: $font-family-merriweather;
      color: $color-ffffff;
      font-size: 20px;
      margin-bottom: 2rem;
    }

    p {
      font-family: $font-family-crimson-pro;
      font-weight: $font-weight-bold;
      color: $info-txt;
      max-width: 80%;
      letter-spacing: 0.45px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;

      @media screen and (min-width: 900px) {
        margin-right: 5rem;
      }
    }
  }

  .kontakt-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    svg {
      fill: $btn;
      margin-right: 10px;
      font-size: 2rem;
    }

    p {
      color: $info-txt;
      font-family: $font-family-merriweather;
      font-weight: $font-weight-bold;
      font-size: 20px;
      display: flex;
      align-items: center;

      .span-flex {
        display: flex;
        flex-direction: column;
      }

      span.small {
        font-family: $font-family-crimson-pro;
        letter-spacing: 0.45px;
        font-size: 15px;
      }
    }
  }
}
