@import '../../styles/variables';

.galleri-hero {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    color: $color-ffffff;
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }
}

.galleri-section {
  background-color: $site-background;
  padding-inline: 1rem;
  padding-block: 3rem;
}
