@import '../../styles/variables';

.about-hero {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    color: $color-ffffff;
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }
}

.about {
  background-color: $site-background;
  padding-inline: 1rem;
  padding-block: 3rem;

  .about-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 900px) {
      flex-direction: row;
      gap: 6rem;
    }
    .about-txt {
      color: $info-txt;
      font-family: $font-family-crimson-pro;
      font-weight: $font-weight-bold;
      font-size: 18px;

      em {
        font-weight: $font-weight-normal;
        font-size: 20px;
      }

      @media screen and (min-width: 900px) {
        width: 70%;
      }
    }

    .about-images {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      img {
        width: 100%;
      }
      @media screen and (min-width: 900px) {
        width: 30%;
      }
    }
  }
}
