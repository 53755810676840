@import '../../styles/variables';

.news-hero {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    color: $color-ffffff;
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }
}

.menu-news-section {
  background-color: $dark-bagcground;
  padding-inline: 1rem;
  padding-block: 3rem;

  h2 {
    font-family: $font-family-merriweather;
    font-size: 2rem;
    color: $color-ffffff;
    margin-bottom: 5rem;

    @media screen and (min-width: 900px) {
      text-align: center;
    }
  }

  .menu-news-item {
    display: flex;
    h3 {
      color: $color-ffffff;
      font-family: $font-family-merriweather;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
    }

    p {
      font-family: $font-family-crimson-pro;
      color: $info-txt;
      font-weight: $font-weight-bold;
      width: 70%;
      line-height: 30px;
      letter-spacing: 0.45px;
    }

    img {
      clip-path: circle(50% at 50% 50%);
      width: 100%;
      display: none;
      margin-right: 8rem;

      @media screen and (min-width: 900px) {
        display: block;
        width: 200px;
        height: 200px;
      }
    }
  }
}

.news-section {
  background-color: $site-background;
  padding-inline: 1rem;
  padding-block: 3rem;

  h2 {
    font-family: $font-family-merriweather;
    font-size: 2rem;
    color: $color-ffffff;
    margin-bottom: 2rem;
    @media screen and (min-width: 900px) {
      text-align: center;
    }
  }
  .news-list {
    height: 600px;
    overflow-y: auto;

    @media screen and (min-width: 900px) {
      padding-right: 10rem;
    }

    .news-item {
      border-top: 2px solid $info-txt;
      padding-block: 2rem;

      &:last-child {
        border-bottom: 2px solid $info-txt;
        margin-bottom: 4rem;
      }

      h3 {
        color: $color-ffffff;
        font-family: $font-family-merriweather;
        font-weight: $font-weight-bold;
        margin-bottom: 0.5rem;
      }

      span {
        display: flex;
        align-items: center;
        color: $txt-dark;
        font-family: $font-family-crimson-pro;
        font-weight: $font-weight-bold;
        margin-bottom: 2rem;

        svg {
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .news-item-text {
        display: flex;

        svg {
          font-size: 1.6rem;
          color: $txt-dark;
          margin-right: 0.5rem;
        }

        p {
          font-family: $font-family-crimson-pro;
          color: $txt-dark;
          font-weight: $font-weight-bold;
          width: 70%;
        }
      }
    }

    img {
      display: none;

      @media screen and (min-width: 900px) {
        display: block;
        height: 15vw;
        max-height: 200px;
      }
    }
  }
}

.comment-box {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  @media screen and (min-width: 900px) {
    button {
      width: 30%;
    }
  }

  h3 {
    font-family: $font-family-merriweather;
    font-weight: $font-weight-bold;
    color: $info-txt;
    font-size: 20px;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    margin-inline: auto;

    @media screen and (min-width: 900px) {
      margin-inline: 0;
    }
  }
}
